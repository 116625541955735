import React, { Component } from 'react'
import { Layout, Menu } from 'antd'
import { FormOutlined, HomeFilled, LogoutOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import AuthController from '../controllers/AuthController'
import Constants from '../shared/Constants'

const { Header } = Layout
const { SubMenu } = Menu

export default class TopNavigation extends Component {
  signOutPressed() {
    AuthController.signOut()
  }

  render() {
    return (
      <Header>
        <Menu theme="dark" mode="horizontal">
          <Menu.Item icon={<HomeFilled />} key="1">
            <Link to={Constants.ROUTES.PAGES.HOME}>홈</Link>
          </Menu.Item>
          <SubMenu key="add" icon={<FormOutlined />} title="재계약서 작성">
            <Menu.Item key="add1">
              <Link to={Constants.ROUTES.PAGES.RENEW_BUSINESS_FORM}>사업자 재계약</Link>
            </Menu.Item>
            <Menu.Item key="add2">
              <Link to={Constants.ROUTES.PAGES.RENEW_PERSONAL_FORM}>개인 재계약</Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="3" onClick={ () => this.signOutPressed()} icon={<LogoutOutlined />}>
            <Link to={Constants.ROUTES.PAGES.HOME}>Sign Out</Link>
          </Menu.Item>          
        </Menu>
      </Header>
    )
  }
}