import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Form, Alert, Space, message } from 'antd'
import { CopyTwoTone } from '@ant-design/icons'
import DateForm from '../components/DateForm'
import Constants from '../shared/Constants'
import PersonalForm from '../components/PersonalForm'
import FinanceForm from '../components/FinanceForm'
import CommentForm from '../components/CommentForm'
import PersonalContractInfo from '../model/PersonalContractInfo'
import BranchForm from '../components/BranchForm'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const RenewPersonalForm = () => {
  
  const [contractId, setContractId] = useState(null)
  const [dates, setDates] = useState(null)
  const [paymentDate, setPaymentDate] = useState(0)
  const [showPaymentDateAlert, setShowPaymentDateAlert] =  useState(false)
  const [branchId, setBranchId] = useState(null)
  const [form] = Form.useForm();

  const onFinish = (values) => {
    if (paymentDate === 0) { 
      setShowPaymentDateAlert(true)
      return
     } else { 
      setShowPaymentDateAlert(false) 
    }

    PersonalContractInfo.create(values.name, values.contact, values.email, values.address, dates.startDate, dates.endDate, dates.duration, values.price, values.deposit, paymentDate, values.autoPayment, values.room, branchId, values.comment).then((result) => {
      setContractId(result.id)
    }).catch((error) => console.error(error))
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  }

  const linkToContractPage = () => {
    if (contractId === null) { return }
    const url = PersonalContractInfo.contractURLWithId(contractId)
    return (
      <Space size='middle'>
        <CopyToClipboard 
          text={`${Constants.BASE_URL}${url}`} 
          onCopy={() => message.success('URL을 클립보드에 복사했습니다!')}
        >
          <CopyTwoTone />
        </CopyToClipboard>
        <Link to={url}>{`${Constants.BASE_URL}${url}`}</Link>
      </Space>
    )
  }

  return (
    <div style={{padding: "20px"}}>
      <Form 
        form={form}
        {...layout}
        name="personal"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        colon = {false}
        initialValues={{
          duration: Constants.CONTRACT_DURATION_INITIAL_VALUE,
          comment: "",
          autoPayment: false
        }}
      >
        <BranchForm onChangeBranch={(selectedBranchId) => setBranchId(selectedBranchId)} />

        <PersonalForm />
        
        <DateForm onChangeDates={(dates) => setDates(dates) }/>

        <FinanceForm onPaymentDateChange={(value)=> {
          setPaymentDate(value)
        }} />

        <CommentForm />

        { showPaymentDateAlert ? <Alert message="결제일을 선택 해주세요!" type="error" /> : "" }

        <Form.Item {...tailLayout}>
          <Space size='large'>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            <Button type="info" onClick={() => {
                form.resetFields()
                setContractId(null)
                setDates(null)
                setPaymentDate(0)
                setBranchId(null)
                setShowPaymentDateAlert(false)
              }}>
              Clear
            </Button>
          </Space>
        </Form.Item>

        <Form.Item {...tailLayout}>
          { linkToContractPage() }
        </Form.Item>  
      </Form>
      
      
      
  </div>
  )
}
  
export default RenewPersonalForm