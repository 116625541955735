import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import FirestoreController from '../controllers/FirestoreController'
import { Button, DatePicker, Form, Row, Col, Input, InputNumber } from 'antd'
import moment from 'moment';

const firestoreController = new FirestoreController()

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

export default class RenewForm extends Component {
  state = {
    contractId: null,
    duration: 6,
    startDate: null,
    endDate: null,
  }

  createRenewContract(companyName, registrationNumber) {
    firestoreController.addNewRenewContract(companyName, registrationNumber).then((result) => {
      this.setState({
        contractId: result.id
      })
    }).catch((error) => console.log(error))
  }

  linkToContractPage() {
    if (this.state.contractId === null) { return }
    return (<Link to={`/renewContract/${this.state.contractId}`}>{`https://pathfinder-tool-e0158.web.app/renewContract/${this.state.contractId}`}</Link>)
  }

  onFinish = (values) => {
    console.log('Success:', values);
  }

  onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  }

  onChangeStartDate = (date) => {
    this.setState({ startDate: date.toDate()}, () => {
      this.changeEndDate()
    })
  }

  onChangeDuration = (duration) => {
    this.setState({ duration: duration }, () => {
      this.changeEndDate()
    })
  }

  changeEndDate() {
    if (!this.state.startDate || !this.state.duration) { return }
    
    var endDate = new Date(this.state.startDate)
    endDate.setDate(endDate.getDate()-1)
    endDate.setMonth(endDate.getMonth()+this.state.duration)
    this.setState({ endDate: endDate })
  }

  render() {
    return (
      <div>
        <Form 
          {...layout}
          name="basic"
          initialValues={{
            companyName: "마스커피랩",
            registrationNumber: ["662", "11", "01521"],
            duration: this.state.duration
          }}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
        >
          <Form.Item
            label="회사명"
            name="companyName"
            rules={[
              {
                required: true,
                message: '회사명 입력!',
              },
            ]}
          >
            <Row>
              <Col span={10}>
                <Input defaultValue="마스커피랩"/>
              </Col>
            </Row>
          </Form.Item>

          <Form.Item
            label="사업자 등록 번호"
            name="registrationNumber"
            rules={[
              {
                required: true,
                message: '사업자 등록 정보 입력!',
              },
            ]}
          >
            <Input.Group>
              <Row gutter={20}>
                <Col span={3}>
                  <Input defaultValue="662" />
                </Col>
                -
                <Col span={2}>
                  <Input defaultValue="11" />
                </Col>
                -
                <Col span={5}>
                  <Input defaultValue="01521" />
                </Col>
              </Row>
            </Input.Group>
          </Form.Item>
          
          

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
        
        { this.linkToContractPage() }
    </div>
    )
  }
}
