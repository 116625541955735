import PDFGenerator from "./PDFGenerator";

export default class PersonalContractPDFGenerator extends PDFGenerator {
  constructor(contractInfo) {
    super(contractInfo)
    this.contractInfo = contractInfo
    this.presentationObject = contractInfo.presentationObject()
  }

  async generate(signature) {
    await this.initialize('/resources/personal_contract_template.pdf')
    this.page = this.page()
    const { width, height } = this.page.getSize()

    this.width = width
    this.height = height
    
    this.addName()

    this.addContact()

    this.addEmail()

    this.addAddress()
    
    this.addDate()

    this.addBranch()

    this.addPrice()

    this.addAccount()

    this.addComment()

    this.addCustomer()

    await this.addSignature(signature)
  }

  addName() {
    const x = 80
    const y = this.height - this.body.fontHeight - 104
    this.page.drawText(this.presentationObject.name, {
      x: x,
      y: y,
      size: this.body.size,
      font: this.embeddedFont,
    }) 
  }

  addContact() {
    const x = 80
    const y = this.height - this.body.fontHeight - 125
    this.page.drawText(this.presentationObject.contact, {
      x: x,
      y: y,
      size: this.body.size,
      font: this.embeddedFont,
    }) 
  }

  addEmail() {
    const x = 335
    const y = this.height - this.body.fontHeight - 125
    this.page.drawText(this.presentationObject.email, {
      x: x,
      y: y,
      size: this.body.size,
      font: this.embeddedFont,
    }) 
  }

  addAddress() {
    const x = 80
    const y = this.height - this.body.fontHeight - 146
    this.page.drawText(this.presentationObject.address, {
      x: x,
      y: y,
      size: this.body.size,
      font: this.embeddedFont,
    }) 
  }

  addDate() {
    const x = 60
    const y = this.height - this.body.fontHeight - 194
    
    this.page.drawText(`${this.presentationObject.startDate}부터 ${this.presentationObject.endDate}까지`, {
      x: x,
      y: y,
      size: this.body.size,
      font: this.embeddedFont,
    }) 
  }

  addBranch() {
    const x = 60
    const y = this.height - this.body.fontHeight - 253
    this.page.drawText(`${this.contractInfo.branch.address} ${this.presentationObject.room}`, {
      x: x,
      y: y,
      size: this.body.size,
      font: this.embeddedFont,
    }) 
  }

  addPrice() {
    const x = 60
    const y = this.height - this.body.fontHeight - 313
    this.page.drawText(`보증금: ₩ ${this.presentationObject.deposit}, 이용료: ₩ ${this.presentationObject.price}(부가세포함)`, {
      x: x,
      y: y,
      size: this.body.size,
      font: this.embeddedFont,
    }) 
  }

  addAccount() {
    const x = 60
    const y = this.height - this.body.fontHeight - 334
    this.page.drawText(`이용료는 매월 ${this.presentationObject.paymentDate}에 지정된 입금계좌(${this.contractInfo.branch.bankAccount} ${this.contractInfo.branch.bankAccountName})로 지급한다.`, {
      x: x,
      y: y,
      size: this.body.size,
      font: this.embeddedFont,
    }) 
  }

  addComment() {
    const x = 60
    const y = this.height - this.body.fontHeight - 367
    this.page.drawText(this.presentationObject.comment, {
      x: x,
      y: y,
      size: this.body.size,
      font: this.embeddedFont,
    }) 
  }

  addCustomer() {
    const x = 400
    const y = this.height - this.body.fontHeight - 770
    this.page.drawText(this.presentationObject.name, {
      x: x,
      y: y,
      size: this.body.size,
      font: this.embeddedFont,
    }) 
  }

  async addSignature(signature) {
    const x = 475
    const y = this.height - 792
    const image = await this.pdfDoc.embedPng(signature)

    const scaledImage = image.scale(0.2)
    this.page.drawImage(image, {
      x: x,
      y: y,
      width: scaledImage.width,
      height: scaledImage.height,
    })
  }
}