import React, { Component } from 'react'
import { Layout } from 'antd'
import { Route, Switch } from 'react-router-dom'
import RenewContractList from '../pages/RenewContractList'
import RenewBusinessForm from '../pages/RenewBusinessForm'
import RenewPersonalForm from '../pages/RenewPersonalForm'
import RenewPersonalContract from '../pages/RenewPersonalContract'
import RepayContract from '../pages/RepayContract'
import SignIn from '../pages/SignIn'
import store from '../shared/store'
import TopNavigation from '../components/TopNavigation'
import AppFooter from '../components/AppFooter'
import AuthController from '../controllers/AuthController'
import Constants from './Constants'

const { Content } = Layout

const DefaultContainer = () => {
  return (
    <Layout style={{backgroundColor: 'white'}}>
      <TopNavigation />
      <Content>
        <Switch>
          <Route exact path={Constants.ROUTES.PAGES.HOME} component={RenewContractList} />
          <Route path={Constants.ROUTES.PAGES.RENEW_BUSINESS_FORM} component={RenewBusinessForm} />
          <Route path={Constants.ROUTES.PAGES.RENEW_PERSONAL_FORM} component={RenewPersonalForm} />
        </Switch>
      </Content>
      <AppFooter />
    </Layout>
)}

export default class App extends Component {
  state = {
    isSignedIn: store.getState().isSignedIn
  }

  componentDidMount() {
    AuthController.addStateLinstener()
    store.subscribe(() => {
      this.setState({ 
        isSignedIn: store.getState().isSignedIn
      })
    })
  }

  render() {
    if (!this.state.isSignedIn) {
      return <div>
          <Route exact path={Constants.ROUTES.PAGES.HOME} component={ SignIn } />
          <Route path={`${Constants.ROUTES.PAGES.RENEW_PERSONAL_CONTRACT}/:contractId`} component={RenewPersonalContract} />
          <Route path={`${Constants.ROUTES.PAGES.REPAY_CONTRACT}/:contractId`} component={RepayContract} />
        </div>
    }
    return (
      <div>
        <Route exact path={Constants.ROUTES.PAGES.HOME} component={ DefaultContainer } />
        <Route path={Constants.ROUTES.PAGES.RENEW_BUSINESS_FORM} component={ DefaultContainer } />
        <Route path={Constants.ROUTES.PAGES.RENEW_PERSONAL_FORM} component={ DefaultContainer } />
        <Route path={`${Constants.ROUTES.PAGES.RENEW_PERSONAL_CONTRACT}/:contractId`} component={RenewPersonalContract} />
        <Route path={`${Constants.ROUTES.PAGES.REPAY_CONTRACT}/:contractId`} component={RepayContract} />
      </div>   
    )
  }
}