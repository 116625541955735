import React from 'react'
import { Form, Input } from 'antd'

const { TextArea } = Input

const CommentForm = () => {
  return (
    <Form.Item label="비고" name="comment">
      <TextArea style={{ width: '300px'}} rows={2} />
    </Form.Item>
  )
}


export default CommentForm