import React, { useState } from 'react'
import AuthController from '../controllers/AuthController'
import { Button, Input, Alert, Space, Image } from 'antd'
import { LoginOutlined, UserOutlined, LockOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import logo from '../resoucres/logo.png'


const Login = () => {
  const [emailRef, setEmailRef] = useState(null)
  const [passwordRef, setPasswordRef] = useState(null)
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  
  const handleSignIn = () => {
    const email = emailRef.state.value
    const password = passwordRef.state.value
    if(email === null || email === undefined || email.length === 0) { 
      setErrorMessage("이메일 확인")
      setShowErrorAlert(true)
      return
    }
    if(password === null || password === undefined || password.length === 0) { 
      setErrorMessage("비밀번호 확인")
      setShowErrorAlert(true)
      return 
    }

    AuthController.signIn(email, password).then(() => {

    }).catch((error) => {
      console.log(error)
      setErrorMessage(error.message)
      setShowErrorAlert(true)
    })
  }

  const errorAlert = () => {
    if (showErrorAlert) {
      return (
        <Alert
          style={{width: "500px"}}
          message={`${errorMessage}`}
          description={`로그인에 실패했습니다. 지속적으로 문제가 발생할 경우 패스파인더로 문의해주세요.`}
          type="error"
          onClose={()=> {
            setShowErrorAlert(false)
          }}
          showIcon
          closable
        />
      )
    }
  }

  return (
    <div>
      <div
        style={{padding: "100px", justifyContent: "center", display: "flex"}} 
      >
        <Space
          direction="vertical"
          size="large"
        >
          <Image
            style={{width: "300px"}}
            src={logo}
            preview={false}
          />
          <Input
            style={{width: "300px"}}
            size="large" placeholder="Email" prefix={<UserOutlined />} ref={(ref) => {
              setEmailRef(ref)
            }} 
          />
          <Input.Password
            style={{width: "300px"}}
            size="large"
            placeholder="Password"
            prefix={<LockOutlined />}
            ref={(ref) => {
              setPasswordRef(ref)
            }}
            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
          <Button variant="primary" onClick={handleSignIn}><Space><LoginOutlined />Sign In</Space></Button>
          
        </Space>
      </div>
      <div
        style={{justifyContent: "center", display: "flex"}} 
      >
        { errorAlert() }
      </div>
      
    </div>
    
  )
}


export default Login;