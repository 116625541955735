import React from 'react'
import { Form, Row,  Select, InputNumber, Space } from 'antd'
import StringHelper from '../shared/StringHelper'

const { Option } = Select

const FinanceForm = ({onPaymentDateChange}) => {
  const options = []
  for (var date = 1; date <= 31; date++) {
    options.push({ label: date, value: date })
  }

  return (
    <>
      <Form.Item
        label="이용료"
        name="price"
        rules={[
          {
            required: true,
            message: '이용료 입력!',
          },
        ]}
      >
        <Space>
          <InputNumber 
            style={{ width: '100%' }}
            step={1000} 
            formatter={value => StringHelper.decimalStringWithComma(value)}
            parser={value => parseInt(value.replaceAll(',', ''))}
          />
          원
        </Space>
      </Form.Item>
      <Form.Item
        label="보증금"
        name="deposit"
        rules={[
          {
            required: true,
            message: '보증금 입력!',
          },
        ]}
      >
        <Row>
          <Space>
            <InputNumber 
              style={{ width: '100%' }}
              step={1000} 
              formatter={value => StringHelper.decimalStringWithComma(value)}
              parser={value => parseInt(value.replaceAll(',', ''))}
            />
            원
          </Space>
        </Row>
      </Form.Item>
      <Form.Item
        label="결제일"
        name="paymentDay"
        rules={[
          {
            required: true,
            message: '결제일 입력!',
          },
        ]}
      >
        <Select 
          style={{ width: '170px' }}
          onChange={onPaymentDateChange}
          options={options}
        />
      </Form.Item>
      <Form.Item
        label="정기결제 여부"
        name="autoPayment"
      >
        <Select 
          style={{ width: '170px' }}
          defaultValue={false}
        >      
          <Option value={false}>월별 결제</Option>
          <Option value={true}>정기 결제</Option>
        </Select>
      </Form.Item>
    </>
  )
}


export default FinanceForm