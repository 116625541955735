import React from 'react'
import {Layout, Row, Col} from 'antd'

const {Footer} = Layout

const AppFooter = () => {
  return <Footer style={{paddingLeft: 200, paddingRight: 200}}>
    <Row>
      <Col span={12}>
        <div>PATHFINDER Coworking Space</div>
        <div>Copyright © 2019 PATHFINDER Inc. All rights reserved.</div>
      </Col>
      <Col span={12} style={{textAlign: 'right'}}>
      <div>
      주식회사 패스파인더 | 대표이사 김광휘, 이정욱
      </div>
      
      <div>
      사업자등록번호: 572-88-00968
      </div>
      <div>
      본사: 부산시 금정구 금강로 271 미주빌딩 3층
      </div>
      <div>
      TEL: 0507-1415-9080
      </div>
      <div>
      contact@pathfinder.camp
      </div>
      </Col>
    </Row>
    

      

    
  </Footer>
}

export default AppFooter