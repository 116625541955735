import FirestoreController from '../controllers/FirestoreController'
import firebase from 'firebase/app'
import ContractInfo from './ContractInfo'
import moment from 'moment'
import StringHelper from '../shared/StringHelper'
import Constants from '../shared/Constants'
import ContractState from './ContractState'

export default class PersonalContractInfo extends ContractInfo {

  constructor(id, name, contact, email, address, startDate, endDate, duration, price, deposit, paymentDate, autoPayment, room, branchId, comment, downloadURL, state, createdAt, updatedAt) {
    super(id, startDate, endDate, duration, price, deposit, paymentDate, autoPayment, room, branchId, comment, downloadURL, state, createdAt, updatedAt)
    this.name = name
    this.contact = contact
    this.email = email
    this.address = address    
  }

  presentationObject() {
    const formatString = StringHelper.dateFormatForKorean()
    const autoPaymentString = this.autoPayment? '정기 결제': '월별 결제'
    return {
      id: this.id,
      key: this.id,
      name: this.name,
      contact: this.contact,
      email: this.email,
      address: this.address,
      startDate: moment(this.startDate).format(formatString),
      endDate: moment(this.endDate).format(formatString),
      duration: `${this.duration} 개월`,
      price: `${StringHelper.decimalStringWithComma(this.price)} 원`,
      deposit: `${StringHelper.decimalStringWithComma(this.deposit)} 원`,
      paymentDate: `${this.paymentDate} 일`,
      autoPayment: autoPaymentString,
      branch: this.branch,
      createdAt: moment(this.createdAt).format(formatString),
      updatedAt: moment(this.updatedAt).format(formatString),
      contractURL: this.contractURL(),
      room: `${this.room} 호`,
      state: this.state,
      comment: this.comment,
      downloadURL: this.downloadURL,
      instance: this
    }
  }

  contractURL() {
    return `${Constants.ROUTES.PAGES.RENEW_PERSONAL_CONTRACT}/${this.id}`
  }

  static contractURLWithId(id) {
    return `${Constants.ROUTES.PAGES.RENEW_PERSONAL_CONTRACT}/${id}`
  }

  static create(name, contact, email, address, startDate, endDate, duration, price, deposit, paymentDate, autoPayment, room, branchId, comment) {
    const contractInfo = {
      name: name,
      contact: contact,
      email: email,
      address: address,
      startDate: startDate,
      endDate: endDate,
      duration: duration,
      price: parseInt(price.replaceAll(',', '')),
      deposit: parseInt(deposit.replaceAll(',', '')),
      paymentDate: paymentDate,
      autoPayment: autoPayment,
      branchId: branchId,
      room: room,
      state: ContractState.waitingPayment.state,
      comment: comment,
      downloadURL: ""
    }

    contractInfo["createdAt"] = firebase.firestore.FieldValue.serverTimestamp()
    contractInfo["updatedAt"] = firebase.firestore.FieldValue.serverTimestamp()

    return FirestoreController.instance.addNewPersonalRenewContract(contractInfo)
  }

  next(completionHandler, errorHandler) {
    this.state = this.state.next()
    FirestoreController.instance.updatePersonalContractState(this).then(() => completionHandler()).catch((error) => {
      this.state.prev()
      errorHandler(error)
    })
  }

  delete() {
    return FirestoreController.instance.deletePersonalContractInfo(this.id)
  }
}