import { firestore } from './Firebase'
import PersonalContractInfo from '../model/PersonalContractInfo'

const CONTRACTS_COLLECTION = 'contracts'
const PERSONAL_CONTRACTS_COLLECTION = 'personal_contracts'

export default class FirestoreController {
  static instance = new FirestoreController()
  
  addNewRenewContract(companyName, registrationNumber) {
    return firestore.collection(CONTRACTS_COLLECTION).add({
      companyName: companyName,
      registrationNumber: registrationNumber
    })
  }

  addNewPersonalRenewContract(contractInfo) {
    return firestore.collection(PERSONAL_CONTRACTS_COLLECTION).add(contractInfo)
  }

  personalContractCollectionOrderByCreatedAt(completionHandler, errorHandler) {
    return firestore.collection(PERSONAL_CONTRACTS_COLLECTION).orderBy('createdAt', 'desc').onSnapshot(snapshot => {
      if (snapshot.size) {
        let objects = []
        snapshot.forEach(doc => {
          const data = doc.data()
          const object = new PersonalContractInfo(doc.id, data.name, data.contact, data.email, data.address, data.startDate.toDate(), 
                                                        data.endDate.toDate(), data.duration, data.price, data.deposit, data.paymentDate, data.autoPayment, data.room, data.branchId,
                                                        data.comment, data.downloadURL, data.state, data.createdAt.toDate(), data.updatedAt.toDate())
          objects.push(object)
        })
        completionHandler(objects)
      } else {
        errorHandler(new Error("Empty Snapshot"))
      }
    }, error => {
      errorHandler(error)
    })
  }
  
  getPersonalRenewContract(id) {
    return firestore.collection(PERSONAL_CONTRACTS_COLLECTION).doc(id).get()
  }

  getRenewContract(id) {
    return firestore.collection(CONTRACTS_COLLECTION).doc(id).get()
  }

  getAllRenewContracts() {
    return firestore.collection(CONTRACTS_COLLECTION).get()
  }

  updatePersonalContractState(contractInfo) {
    return firestore.collection(PERSONAL_CONTRACTS_COLLECTION).doc(contractInfo.id).update({ state: contractInfo.state.value() })   
  }

  updatePersonalContractDownloadURL(contractInfo) {
    return firestore.collection(PERSONAL_CONTRACTS_COLLECTION).doc(contractInfo.id).update({ downloadURL: contractInfo.downloadURL })   
  }

  deletePersonalContractInfo(id) {
    return firestore.collection(PERSONAL_CONTRACTS_COLLECTION).doc(id).delete()
  }
}