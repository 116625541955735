import branches from '../resoucres/branches.json'

export default class Branch {
  
  constructor(id) {
    this.id = id
    const branch = branches[id]

    this.name = branch.name
    this.registrationNumber = branch.registrationNumber
    this.address = branch.address
    this.phone = branch.phone
    this.fax = branch.fax
    this.bankAccount = branch.bankAccount
    this.bankAccountName = branch.bankAccountName
  }

}