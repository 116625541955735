import React from 'react'
import {Descriptions, Tag} from 'antd'

const ContractDescription = ({type, contractInfo, isRepayment}) => {
  if (contractInfo === null) { return (<></>) }
  const data = contractInfo.presentationObject()
  const persnoalInfo = () => {
    return (
      <>
      <Descriptions.Item label="이름">{data.name}</Descriptions.Item>
      <Descriptions.Item label="연락처">{data.contact}</Descriptions.Item>
      <Descriptions.Item label="이메일">{data.email}</Descriptions.Item>
      <Descriptions.Item label="주소" span={3}>{data.address}</Descriptions.Item>
      </>
    )
  }

  const businessInfo = () => {
    return (
      <></>
    )
  }

  return (<div>
    <Descriptions title="계약 정보" bordered extra={( !isRepayment && <Tag color={data.state.color()}>{data.state.toString()}</Tag>)}
       column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
    >
      { type === 'personal' ? persnoalInfo() : businessInfo() } 
      <Descriptions.Item label="계약 지점">{data.branch.name}</Descriptions.Item>
      <Descriptions.Item label="계약 호실">{data.room}</Descriptions.Item>
      <Descriptions.Item label="정기 결제 여부">{data.autoPayment}</Descriptions.Item>
      <Descriptions.Item label="계약 시작일">{data.startDate}</Descriptions.Item>
      <Descriptions.Item label="계약 종료일">{data.endDate}</Descriptions.Item>
      <Descriptions.Item label="기간">{data.duration}</Descriptions.Item>
      <Descriptions.Item label="이용료">{data.price}</Descriptions.Item>
      <Descriptions.Item label="보증금">{data.deposit}</Descriptions.Item>
      <Descriptions.Item label="결제일">{data.paymentDate}</Descriptions.Item>
      <Descriptions.Item label="비고" span={3}>{data.comment}</Descriptions.Item>
    </Descriptions>
  </div>)
}

export default ContractDescription