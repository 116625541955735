import Branch from './Branch'
import ContractState from './ContractState'
import FirestoreController from '../controllers/FirestoreController'
import StringHelper from '../shared/StringHelper'

export default class ContractInfo {

  constructor(id, startDate, endDate, duration, price, deposit, paymentDate, autoPayment, room, branchId, comment, downloadURL, state, createdAt, updatedAt) {
    this.id = id
    this.startDate = startDate
    this.endDate = endDate
    this.duration = duration
    this.price = price
    this.deposit = deposit
    this.paymentDate = paymentDate
    this.autoPayment = autoPayment
    this.branchId = branchId
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.room = room
    this.branch = new Branch(branchId)
    this.state = new ContractState(state)
    this.comment = comment
    this.downloadURL = downloadURL
  }

  updateDownloadURL(downloadURL) {
    this.downloadURL = downloadURL
    return FirestoreController.instance.updatePersonalContractDownloadURL(this)
  }
  
  autoPaymentDescription() {
    // 6개월동안 매 월 25일 19,900원 결제됩니다.
    return `✔️  정기 결제 계약으로 ${this.duration}개월 동안 매 월 ${this.paymentDate}일에 ${StringHelper.decimalStringWithComma(this.price)}원 결제 됩니다.`
  }
}