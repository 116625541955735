import {createStore} from 'redux';
import Constants from './Constants'

//지점 정보를 리덕스에 저장
export default createStore(function(state, action){
    if(state === undefined){
        return { isSignedIn: false}
    }
    if(action.type === Constants.REDUX_ACTION.SIGNIN){
        return {...state, isSignedIn: action.isSignedIn}
    }
    return state;
}, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())