import React, { useState } from 'react'
import moment from 'moment'
import { DatePicker, Form, InputNumber, Space } from 'antd'
import Constants from '../shared/Constants'

const DateForm = ({onChangeDates}) => {
  const [endDate, setEndDate] = useState(null)
  
  const [startDate, setStartDate] = useState(null)
  
  const [duration, setDuration] = useState(Constants.CONTRACT_DURATION_INITIAL_VALUE)

  const onChangeStartDate = (newStartDate) => {
    if (newStartDate === null || newStartDate === undefined) { return }
    setStartDate(newStartDate.toDate())
    changeEndDate(newStartDate.toDate(), duration)
  }
  
  const onChangeDuration = (newDuration) => {
    if (newDuration === null || newDuration === undefined) { return }
    setDuration(newDuration)
    changeEndDate(startDate, newDuration)
  }

  const changeEndDate = (newStartDate, newDuration) => {
    var newEndDate = new Date(newStartDate)
    newEndDate.setMonth(newEndDate.getMonth() + newDuration)
    newEndDate.setDate(newEndDate.getDate() - 1)
    
    setEndDate(newEndDate)

    onChangeDates({
      startDate: newStartDate,
      endDate: newEndDate,
      duration: newDuration
    })
  }
  
  return (
    <>
      <Form.Item
        label="계약 시작일"
        name="startDate"
        rules={[
          {
            required: true,
            message: '임대 시작일 입력!',
          },
        ]}
      >
        <DatePicker onChange={onChangeStartDate} />
      </Form.Item>
      <Form.Item
        label="기간"
        name="duration"
        rules={[
          {
            required: true,
            message: '기간 입력!',
          },
        ]}
      >
        <Space>
          <InputNumber onChange={onChangeDuration} /> 개월  
        </Space>
      </Form.Item>
      <Form.Item
        label="계약 종료일"
      >
        {endDate === null ? "": moment(endDate).format('YYYY-MM-DD') }
      </Form.Item>  
    </>
  )
}

export default DateForm