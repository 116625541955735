module.exports = {
  decimalStringWithComma(decimal) {
    var regexp = /\B(?=(\d{3})+(?!\d))/g;
    return `${decimal}`.toString().replace(regexp, ',')
  },

  dateFormatForKorean() {
    return 'YYYY년 MM월 DD일'
  },

  dateFormatForSimple() {
    return 'YYYY-MM-DD'
  },

  removeComma(string) {
    return string.replaceAll(',', '')
  }
}