import React, { useState, useEffect } from 'react'
import { Button, Modal, Space, Steps, message, Alert, Layout } from 'antd'
import SignatureCanvas from  '../components/SignatureCanvas'
import FirestoreController from '../controllers/FirestoreController'
import BootPayController from '../controllers/BootPayController'
import PersonalContractInfo from '../model/PersonalContractInfo'
import { useParams } from 'react-router-dom'
import { CreditCardTwoTone, HighlightTwoTone, LoadingOutlined, DownloadOutlined } from '@ant-design/icons'
import ContractDescription from '../components/ContractDescription'
import ContractState from '../model/ContractState'
import lodash from 'lodash'
import PersnoalContractPDFGenerator from '../PDFGenerator/PersnoalContractPDFGenerator'
import { firebase } from '../controllers/Firebase'
import AppFooter from '../components/AppFooter'


const { Step } = Steps
const { Content } = Layout

const firestoreController = new FirestoreController()
const bootpayController = new BootPayController()
// Create styles


const RenewPersonalContract = () => {

  const [showModal, setShowModal] = useState(false)
  const [contractInfo, setContractInfo] = useState(null) 
  const [signatureCanvas, setSignatureCanvas] = useState(null)
  const [isUploading, setIsUploading] = useState(false)
  const [showError, setShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const { contractId } = useParams()
 
  useEffect(() => {
    firestoreController.getPersonalRenewContract(contractId).then((doc) => {
      const data = doc.data()
      const object = new PersonalContractInfo(doc.id, data.name, data.contact, data.email, data.address, data.startDate.toDate(), 
                                                data.endDate.toDate(), data.duration, data.price, data.deposit, data.paymentDate, data.autoPayment, 
                                                data.room, data.branchId, data.comment, data.downloadURL, data.state, data.createdAt.toDate(), data.updatedAt.toDate())
      setContractInfo(object)
    }).catch((error) => console.error(error))
  }, [contractId])


  const handleModalClose = () => {
    setIsUploading(true)
    const signatureBase64 = signatureCanvas.getSignature()
    setShowModal(false)
    const generator = new PersnoalContractPDFGenerator(contractInfo)
    generator.generate(signatureBase64).then(() => {
      generator.upload().then((uploadTask) => {
        const onError = (error) => {
          setIsUploading(false)
          setErrorMessage(error.message)
          setShowError(true)
          console.error(error)
        }
        const onComplete = () => {
          uploadTask.ref.getDownloadURL().then((downloadURL) => {
            contractInfo.updateDownloadURL(downloadURL).then(() => {
              contractInfo.next(() => {
                setIsUploading(false)
                setContractInfo(lodash.cloneDeep(contractInfo))
              }, (error)=> {
                setIsUploading(false)
                setErrorMessage(error.message)
                setShowError(true)
                console.error(error)
              })
            }).catch((error) => {
              setIsUploading(false)
              setErrorMessage(error.message)
              setShowError(true)
              console.error(error)
            })
          })
        }
        uploadTask.task.on(firebase.storage.TaskEvent.STATE_CHANGED, null, onError, onComplete) 
      })
    }).catch((error) => {
      setIsUploading(false)
      setErrorMessage(error.message)
      setShowError(true)
      console.error(error)
    })
  }

  const handlModalCancel = () => {
    setShowModal(false)
    signatureCanvas.clear()
  }

  //Move this part to Controller or Processing here
  const handlePaymentPressed = () => {
    if(contractInfo.autoPayment) {
      console.log("Done!", contractInfo)
      bootpayController.autoPayment(contractInfo, (data) => {
        contractInfo.next(() => {
          setContractInfo(lodash.cloneDeep(contractInfo))
        }, (error)=> {
          setErrorMessage(error.message)
          setShowError(true)
          console.error(error)
        })
      }, (error, data) => {
        if(error.message === "cancel") { 
          message.info("결제를 취소하였습니다")
          return 
        } 
        setErrorMessage(error.message)
        setShowError(true)
        console.error(error)
      })
    } else {
      bootpayController.payment(contractInfo, (data) => {
        // console.log("Done!", contractInfo)
        contractInfo.next(() => {
          setContractInfo(lodash.cloneDeep(contractInfo))
        }, (error)=> {
          setErrorMessage(error.message)
          setShowError(true)
          console.error(error)
        })
      }, (error, data) => {
        if(error.message === "cancel") { 
          message.info("결제를 취소하였습니다")
          return 
        } 
        setErrorMessage(error.message)
        setShowError(true)
        console.error(error)
      })
    }
  }
  
  

  const linkToPDFFile = () => {
    if (contractInfo.state.value() === ContractState.contractFinished.value()) { return <Button variant='success'><a href={contractInfo.downloadURL}><Space><DownloadOutlined />계약서 다운로드</Space></a></Button> }
  }

  const paymentButton = () => {
    if (contractInfo.state.value() === ContractState.waitingPayment.value()) { return <Button variant="primary" onClick={() => handlePaymentPressed()}><Space><CreditCardTwoTone />결제 하기</Space></Button> }
  }

  const signatureButton = () => {
    if (contractInfo.state.value() === ContractState.waitingSignning.value() && !isUploading) { return <Button variant='success' onClick={() => setShowModal(true)}><Space><HighlightTwoTone />서명 하기</Space></Button> }
    if (isUploading) { return <Space><LoadingOutlined /> 계약서 생성 중</Space> }
  }

  const stepElement = () => {
    return (
      <Steps current={contractInfo.state.step()} responsive="true">
        <Step title="이용료 결제하기" description="계약 내용을 확인하시고 결제를 진행해주세요" />
        <Step title="계약서 서명하기" description="계약서에 서명을 진행해주세요" />
        <Step title="계약서 다운로드" description="PDF로 작성된 계약서를 다운로드 하시면 됩니다" />
      </Steps>
    )
  }

  const errorAlert = () => {
    if (showError) {
      return (
        <Alert
        message="오류 발생"
        description={`오류 발생으로 결제/서명에 실패했습니다. 지속적으로 문제가 발생할 경우 패스파인더로 문의해주세요. 오류 메세지: ${errorMessage}`}
        type="error"
        showIcon
        closable
        onClose={() => {
          setShowError(false)
        }}
      />
      )
    }
  }

  const autoPaymentDescription = () => {
    if (contractInfo.autoPayment) {
      return contractInfo.autoPaymentDescription()
    }
  }
  
  if (contractInfo === null) { return <></>}
  return (
    <Layout style={{backgroundColor: 'white'}}>
      <Content style={{ textAlign: 'center', padding: '20px', marginTop: 50, marginBottom: 100 }}>
      <Modal title="서명 패드" visible={showModal} onOk={handleModalClose} onCancel={handlModalCancel}>
        <SignatureCanvas 
          ref={(ref) => { setSignatureCanvas(ref) }}
        />
      </Modal>
        
        <Space style={{ textAlign: 'start' }} direction='vertical' size='large'>
          
          { stepElement() }
          
          <ContractDescription type={'personal'} contractInfo={contractInfo} isRepayment={false} />

          { autoPaymentDescription() }

          { errorAlert() }

          { paymentButton() }

          { signatureButton() }

          { linkToPDFFile() }

        </Space>
      </Content>
      <AppFooter />
        
        
    </Layout>
  )
  
}

export default RenewPersonalContract