import BootPay from "bootpay-js"
import Constants from '../shared/Constants'
import moment from 'moment'

export default class BootPayController {
  static instance = new BootPayController()

  removePaymentWindow() {
    BootPay.removePaymentWindow();
  }

  validateReceipt(receiptId, price) {
    const url = new URL('/v1/verify', 'https://api.pathfinder.camp')
    const searchParams = new URLSearchParams([['receipt', receiptId], ['price', price]])
    url.search = searchParams.toString()
    return fetch(url, {
      method: 'GET', 
      cache: 'no-cache',
    }).then(response => {
      return response.json()
    })
  }

  async payment(contractInfo, completionHandler, errorHandler) {
    this.paymentProcess(contractInfo).error((data) => {
      errorHandler(new Error("error"), data)
    }).cancel((data) => {
      errorHandler(new Error("cancel"), data)
    }).ready((data) => {
    }).confirm((data) => {
      BootPay.transactionConfirm(data);
    }).close((data) => {
    }).done(async (data) => {
      const result = await this.validateReceipt(data.receipt_id, contractInfo.price)
      if (result.code === 0) {
        completionHandler()
      } else {
        const error = new Error()
        error.message = `${result.body.message} Code: ${result.code} `
        errorHandler(error)
      }
    });
  }

  async autoPayment(contractInfo, completionHandler, errorHandler) {
    const result = await this.getUserToken(contractInfo)
    if (result.code !== 0) {
      const error = Error(result.message)
      errorHandler(error)
      return
    }
    this.autoPaymentProcess(contractInfo, result.data.user_token).error((data) => {
      errorHandler(new Error("error"), data)
    }).cancel((data) => {
      errorHandler(new Error("cancel"), data)
    }).ready((data) => {
    }).confirm((data) => {
      BootPay.transactionConfirm(data);
    }).close((data) => {
    }).done(async (data) => {
      const validationResult = await this.validateReceipt(data.receipt_id, contractInfo.price)
      if (validationResult.code === 0) {
        completionHandler()
      } else {
        const error = new Error(`${validationResult.message} Code: ${validationResult.code} `)
        errorHandler(error)
      }
    });
  }

  getUserToken(contractInfo) {
    const url = new URL('/v1/user-token', 'https://api.pathfinder.camp')
    const searchParams = new URLSearchParams([
      ['userId', contractInfo.contact], 
      ['email', contractInfo.email],
      ['name', contractInfo.name],
      ['phone', contractInfo.contact],
    ])
    
    url.search = searchParams.toString()
    return fetch(url, {
      method: 'GET', 
      cache: 'no-cache',
    }).then(response => {
      return response.json()
    })
  }

  autoPaymentProcess(contractInfo, userToken) {
    return BootPay.request({
      price: contractInfo.price, //실제 결제되는 가격
      application_id: Constants.BOOTPAY.APPLICATION_ID,
      name: '패스파인더 임대료 정기 결제', //결제창에서 보여질 이름
      pg: 'nicepay',
      method: 'card_rebill',
      // user_token: userToken,
      show_agree_window: 0, // 부트페이 정보 동의 창 보이기 여부
      items: [
        {
          item_name: '임대료', //상품명
          qty: 1, //수량
          unique: contractInfo.id, //해당 상품을 구분짓는 primary key
          price: contractInfo.price, //상품 단가
          cat1: '재계약', // 대표 상품의 카테고리 상, 50글자 이내
          cat2: '멤버쉽', // 대표 상품의 카테고리 중, 50글자 이내
          cat3: '임대료', // 대표상품의 카테고리 하, 50글자 이내
        }
      ],
      user_info: {
        username: contractInfo.name,
        email: contractInfo.email,
        addr: contractInfo.address,
        phone: contractInfo.contact
      },
      order_id: `${contractInfo.id}-${moment().format('YYYY-MM-DD')}`, //고유 주문번호로, 생성하신 값을 보내주셔야 합니다.
      params: {},
    })
  }

  paymentProcess(contractInfo) {
    return BootPay.request({
      price: contractInfo.price, //실제 결제되는 가격
      application_id: Constants.BOOTPAY.APPLICATION_ID,
      name: '패스파인더 임대료', //결제창에서 보여질 이름
      pg: 'nicepay',
      show_agree_window: 0, // 부트페이 정보 동의 창 보이기 여부
      items: [
        {
          item_name: '임대료', //상품명
          qty: 1, //수량
          unique: contractInfo.id, //해당 상품을 구분짓는 primary key
          price: contractInfo.price, //상품 단가
          cat1: '재계약', // 대표 상품의 카테고리 상, 50글자 이내
          cat2: '멤버쉽', // 대표 상품의 카테고리 중, 50글자 이내
          cat3: '임대료', // 대표상품의 카테고리 하, 50글자 이내
        }
      ],
      user_info: {
        username: contractInfo.name,
        email: contractInfo.email,
        addr: contractInfo.address,
        phone: contractInfo.contact
      },
      order_id: `${contractInfo.id}-${moment().format('YYYY-MM-DD')}`, //고유 주문번호로, 생성하신 값을 보내주셔야 합니다.
      params: {},
      // account_expire_at: '2020-10-25', // 가상계좌 입금기간 제한 ( yyyy-mm-dd 포멧으로 입력해주세요. 가상계좌만 적용됩니다. )
      // extra: {
      //     start_at: '2019-05-10', // 정기 결제 시작일 - 시작일을 지정하지 않으면 그 날 당일로부터 결제가 가능한 Billing key 지급
      //   end_at: '2022-05-10', // 정기결제 만료일 -  기간 없음 - 무제한
      //       vbank_result: 1, // 가상계좌 사용시 사용, 가상계좌 결과창을 볼지(1), 말지(0), 미설정시 봄(1)
      //       quota: '0,2,3', // 결제금액이 5만원 이상시 할부개월 허용범위를 설정할 수 있음, [0(일시불), 2개월, 3개월] 허용, 미설정시 12개월까지 허용,
      //   theme: 'purple', // [ red, purple(기본), custom ]
      //   custom_background: '#00a086', // [ theme가 custom 일 때 background 색상 지정 가능 ]
      //   custom_font_color: '#ffffff' // [ theme가 custom 일 때 font color 색상 지정 가능 ]
      // }
    })
  }
}