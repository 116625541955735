import React, { useState, useEffect } from 'react'
import { Button, Space, message, Alert, Layout } from 'antd'
import FirestoreController from '../controllers/FirestoreController'
import BootPayController from '../controllers/BootPayController'
import PersonalContractInfo from '../model/PersonalContractInfo'
import { useParams } from 'react-router-dom'
import { CreditCardTwoTone } from '@ant-design/icons'
import ContractDescription from '../components/ContractDescription'
import lodash from 'lodash'
import AppFooter from '../components/AppFooter'

const { Content } = Layout

const firestoreController = new FirestoreController()
const bootpayController = new BootPayController()

const RepayContract = () => {
  const [contractInfo, setContractInfo] = useState(null) 
  const [showError, setShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const { contractId } = useParams()
 
  useEffect(() => {
    firestoreController.getPersonalRenewContract(contractId).then((doc) => {
      const data = doc.data()
      const object = new PersonalContractInfo(doc.id, data.name, data.contact, data.email, data.address, data.startDate.toDate(), 
                                                data.endDate.toDate(), data.duration, data.price, data.deposit, data.paymentDate, 
                                                data.room, data.branchId, data.comment, data.downloadURL, data.state, data.createdAt.toDate(), data.updatedAt.toDate())
      setContractInfo(object)
    }).catch((error) => console.error(error))
  }, [contractId])

  const handlePaymentPressed = () => {
    bootpayController.payment(contractInfo, (data) => {
      contractInfo.next(() => {
        message.success("결제가 완료되었습니다. 감사합니다.")
      }, (error)=> {
        setErrorMessage(error.message)
        setShowError(true)
        console.error(error)
      })
    }, (error, data) => {
      if(error.message === "cancel") { 
        message.info("결제를 취소하였습니다")
        return 
      } 

      setErrorMessage(error.message)
      setShowError(true)
      console.error(error)
    })
  }
  
  

  const errorAlert = () => {
    if (showError) {
      return (
        <Alert
        message="오류 발생"
        description={`오류 발생으로 결제/서명에 실패했습니다. 지속적으로 문제가 발생할 경우 패스파인더로 문의해주세요. 오류 메세지: ${errorMessage}`}
        type="error"
        showIcon
        closable
      />
      )
    }
  }
  
  if (contractInfo === null) { return <></>}
  return (
    <Layout style={{backgroundColor: 'white'}}>
      <Content style={{ textAlign: 'center', padding: '20px', marginTop: 50, marginBottom: 100 }}>        
        <Space style={{ textAlign: 'start' }} direction='vertical' size='large'>
          
          <ContractDescription type={'personal'} contractInfo={contractInfo} isRepayment={true} />

          { errorAlert() }

          <Button variant="primary" onClick={() => handlePaymentPressed()}><Space><CreditCardTwoTone />결제 하기</Space></Button>

        </Space>
      </Content>
      <AppFooter />
        
        
    </Layout>
  )
  
}

export default RepayContract