import React from 'react'
import { Form, Input } from 'antd'

const PersonalForm = () => {
  return (
    <>
      <Form.Item
          label="고객명"
          name="name"
          rules={[
            {
              required: true,
              message: '고객명 입력!',
            },
          ]}
        >
        <Input style={{ width: 170 }} />
      </Form.Item>
      <Form.Item
        label="연락처"
        name="contact"
        rules={[
          {
            required: true,
            message: '연락처!',
          },
        ]}
      >
        <Input style={{ width: 170 }} />
      </Form.Item>
      <Form.Item
        label="이메일"
        name="email"
        rules={[
          {
            required: true,
            message: '이메일!',
          },
        ]}
      >
        <Input style={{ width: 250 }} />
      </Form.Item>
      <Form.Item
        label="주소"
        name="address"
        rules={[
          {
            required: true,
            message: '주소!',
          },
        ]}
      >
        <Input style={{ width: 400 }} />
      </Form.Item>
    </>
  )
}


export default PersonalForm