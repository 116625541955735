module.exports = {
  BASE_URL: 'https://contract.pathfinder.camp',//'http://localhost:3000',
  REDUX_ACTION: {
    SIGNIN: 'SIGNIN'
  },
  ROUTES: {
    PAGES: {
      HOME: "/",
      RENEW_BUSINESS_FORM: "/renewBusinessForm",
      RENEW_PERSONAL_FORM: "/renewPersonalForm",
      RENEW_PERSONAL_CONTRACT: "/renewPersonalContract",
      REPAY_CONTRACT: "/repayContract"
    }
  },
  BOOTPAY: {
    APPLICATION_ID: "60123b705b2948002751f103"
  },
  CONTRACT_DURATION_INITIAL_VALUE: 0,
}