import { firebase } from './Firebase'
import store from '../shared/store'
import Constants from '../shared/Constants'

export default class AuthController {
  static signInConfig = {
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ]
  }

  static authObject = firebase.auth()

  static addStateLinstener() {
    this.authObject.onAuthStateChanged((user) => {
      if (user === null) {
        store.dispatch({type: Constants.REDUX_ACTION.SIGNIN, isSignedIn: false})
        return
      } 
      store.dispatch({type: Constants.REDUX_ACTION.SIGNIN, isSignedIn: true})
    })
  }

  static signOut() {
    this.authObject.signOut().then(() => {
      store.dispatch({type: Constants.REDUX_ACTION.SIGNIN, isSignedIn: false})
    }).catch((error) => console.log(error) )
  }

  static signIn(email, password) {
    return this.authObject.signInWithEmailAndPassword(email, password)
  }
}