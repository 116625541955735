import React, { useState, useEffect } from 'react'
import { Table, Space, message, Tag, Popconfirm } from 'antd'
import { CopyTwoTone, AuditOutlined, DeleteTwoTone, DollarTwoTone } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import FirestoreController from '../controllers/FirestoreController'
import Constants from '../shared/Constants'
import ContractState from '../model/ContractState'
import moment from 'moment'
import StringHelper from '../shared/StringHelper'
import branches from '../resoucres/branches.json'

const firestoreController = new FirestoreController()


const RenewContractList = () => {
  const [personalContracts, setPersonalContracts] = useState([])
  
  useEffect(() => {
    const unsubscribe = firestoreController.personalContractCollectionOrderByCreatedAt((objects) => {
      console.log("Objects: ", objects)
      setPersonalContracts(objects)
    }, (error) => {
      console.log(error)
    })

    return () => {
      unsubscribe()
    }
  }, [])

  const onConfirm = (contractInfo) => {
    contractInfo.delete().then(() =>{
      message.success('삭제 되었습니다')
    }).catch((error) => {
      message.error('삭제 실패하였습니다')
    })
  }

  const onCancel = () => {
    message.info('삭제를 취소 하였습니다')
  }
  
  const personalOnlyColumns = [
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      sorter: (a, b) => {
        return a.name.localeCompare(b.name)
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '연락처',
      dataIndex: 'contact',
      key: 'contact',
      fixed: 'left'
    },
  ]

  const commonColumns = [
    {
      title: '계약 상태',
      dataIndex: 'state',
      key: 'state',
      render: (state) => <Tag color={state.color()}>{state.toString()}</Tag>
    },
    {
      title: '계약 지점',
      dataIndex: 'branch',
      key: 'branch',
      render: (branch) => <>{branch.name}</>,
      filters: branches.map((branch) => {
        return {
          text: branch.name,
          value: branch.name
        }
      }),
      filterMultiple: false,
      onFilter: (value, record) => {
        return record.branch.name === value
      }
    },
    {
      title: '계약 호실',
      dataIndex: 'room',
      key: 'room',
    },
    {
      title: '계약 시작일',
      dataIndex: 'startDate',
      key: 'startDate',
      sorter: (a, b) => {
        const format = StringHelper.dateFormatForKorean()
        const timeA = moment(a.startDate, format).toDate().getTime()
        const timeB = moment(b.startDate, format).toDate().getTime()
        return timeA - timeB
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '계약 종료일',
      dataIndex: 'endDate',
      key: 'endDate',
      sorter: (a, b) => {
        const format = StringHelper.dateFormatForKorean()
        const timeA = moment(a.endDate, format).toDate().getTime()
        const timeB = moment(b.endDate, format).toDate().getTime()
        return timeA - timeB
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '이용료',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: '정기 / 월별',
      dataIndex: 'autoPayment',
      key: 'autoPayment',
    },
    {
      title: '계약 페이지',
      dataIndex: 'contractURL',
      key: 'contractURL',
      fixed: 'right',
      render: (url, record) => {
        return (<Space size='middle'>
          <CopyToClipboard 
            text={`${Constants.BASE_URL}${url}`} 
            onCopy={() => message.success('URL을 클립보드에 복사했습니다!')}
          >
            <CopyTwoTone twoToneColor="#52c41a" />
          </CopyToClipboard>
          <Link to={url}><AuditOutlined /></Link>
          { deletePopconfirm(record) }
          { repaymentButton(record) }
        </Space>)
      },
    },
  ]

  const deletePopconfirm = (record) => {
    if (record.state.value() === ContractState.waitingPayment.value())
    return (<Popconfirm 
      okText="삭제" 
      cancelText="취소" 
      title="정말로 삭제 하시겠습니까?"
      onCancel={onCancel}
      onConfirm={() => {
        onConfirm(record.instance)
      }}
      >
        <DeleteTwoTone twoToneColor="#eb2f96" />
      </Popconfirm>)
  }

  const repaymentButton = (record) => {
    if (record.state.value() === ContractState.contractFinished.value())
    return (<CopyToClipboard 
      text={`${Constants.BASE_URL + Constants.ROUTES.PAGES.REPAY_CONTRACT}/${record.id}`} 
      onCopy={() => message.success('URL을 클립보드에 복사했습니다!')}
    >
      <DollarTwoTone twoToneColor="#e3d61e" />
    </CopyToClipboard>)
  }

  const personalColumns = personalOnlyColumns.concat(commonColumns)
  return (
    <div>
      <Table 
        style={{padding: "100px"}} 
        dataSource={personalContracts.map((contract) => contract.presentationObject())} 
        columns={personalColumns} 
        scroll={{x: 1300}}
      />
    </div>
  )
}

export default RenewContractList