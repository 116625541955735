import React, { Component } from 'react'
import ReactSignatureCanvas from 'react-signature-canvas'

export default class SignatureCanvas extends Component {

  getSignature() {
    const image = this.signatureCanvas.toDataURL()
    return image
  }

  getSize() {
    return { width: 470, height: 200 }
  }

  clear() {
    this.signatureCanvas.clear()
  }

  render() {
    return (
      <div style={{border: 'dashed', borderWidth: '1px'}}>
        <ReactSignatureCanvas 
        ref={(ref) => { this.signatureCanvas = ref }}
        canvasProps={{width: this.getSize().width, height: this.getSize().height, className: 'signatureCanvas'}}
        />
      </div>
      
    )
  }
}