import React from 'react'
import { Select, Form, Input, Space } from 'antd'
import branches from '../resoucres/branches.json'

const { Option } = Select

const BranchForm = ({onChangeBranch}) => {

  const branchItems = () => {
    return branches.map((branch) => <Option key={branch.id} value={branch.id}>{branch.name}</Option>)
  }

  return (
    <>
      <Form.Item 
        label="지점 선택"
        name="branch"
        rules={[
          {
            required: true,
            message: '지점 선택!',
          },
        ]}
      >
        <Select style={{ width: 170 }} onChange={ onChangeBranch }>
          { branchItems() }        
        </Select>
      </Form.Item>
      <Form.Item
        label="계약 호실"
        name="room"
        rules={[
          {
            required: true,
            message: '호실 입력!',
          },
        ]}
      >
        <Space>
          <Input />
          호
        </Space>
      </Form.Item>
    </>
  )
}


export default BranchForm