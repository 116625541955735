export default class ContractState {
  static waitingPayment = new ContractState('waitingPayment');
  static waitingSignning = new ContractState('waitingSignning')
  static contractFinished = new ContractState('contractFinished')

  constructor(state) {
    this.state = state;
  }

  value() {
    return this.state
  }

  next() {
    switch (this.state) {
      case ContractState.waitingPayment.state: {
        this.state = ContractState.waitingSignning.value()
        return this
      }
      case ContractState.waitingSignning.state: {
        this.state = ContractState.contractFinished.value()
        return this
      }
      case ContractState.contractFinished.state: {
        return this
      }
      default: {
        return 
      }
    }
  }

  prev() {
    switch (this.state) {
      case ContractState.waitingPayment.state: {
        return this
      }
      case ContractState.waitingSignning.state: {
        this.state = ContractState.waitingPayment.value()
        return this
      }
      case ContractState.contractFinished.state: {
        this.state = ContractState.waitingSignning.value()
        return this
      }
      default: {
        return 
      }
    }
  }

  step() {
    switch (this.state) {
      case ContractState.waitingPayment.state: {
        return 0
      }
      case ContractState.waitingSignning.state: {
        return 1
      }
      case ContractState.contractFinished.state: {
        return 2
      }
      default: {
        return null
      }
    }
  }
  
  toString() {
    switch (this.state) {
      case ContractState.waitingPayment.state: {
        return '결제 대기'
      }
      case ContractState.waitingSignning.state: {
        return '서명 대기'
      }
      case ContractState.contractFinished.state: {
        return '계약 완료'
      }
      default: {
        return null
      }
    }
  }

  color() {
    switch (this.state) {
      case ContractState.waitingPayment.state: {
        return 'orange'
      }
      case ContractState.waitingSignning.state: {
        return 'magenta'
      }
      case ContractState.contractFinished.state: {
        return 'green'
      }
      default: {
        return null
      }
    }
  }
}
