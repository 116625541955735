import { degrees, PDFDocument, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import { saveAs } from 'file-saver'
import StorageController from '../controllers/StorageController'

export default class PDFGenerator {
  fontURL = '/resources/NanumGothic.ttf'
  pdfDoc = null
  embedFont = null

  body = {
    size: 8,
    color: rgb(0.1, 0.1, 0.1),
    degree: degrees(0),
    fontHeight: 5.5
  }

  constructor(contractInfo) {
    this.contractInfo = contractInfo
  }

  async initialize(pdfURL) {
    
    const fontResult = await fetch(this.fontURL)
    this.font = await fontResult.arrayBuffer()

    const pdfObject = await fetch(pdfURL)
    const pdfBuffer = await pdfObject.arrayBuffer()
    this.pdfDoc = await PDFDocument.load(pdfBuffer)
    this.pdfDoc.registerFontkit(fontkit);

    this.embeddedFont = await this.pdfDoc.embedFont(this.font);
    
  }

  page() {
    return (this.pdfDoc.getPages())[0]
  }

  async save() {
    const pdfBytes = await this.pdfDoc.save()
    const blob = new Blob([pdfBytes], {type: 'application/pdf'})
    saveAs(blob, this.contractInfo.id)
  }  

  async upload() {
    const pdfBytes = await this.pdfDoc.save()
    const blob = new Blob([pdfBytes], {type: 'application/pdf'})
    const storage = new StorageController()
    return storage.uploadRenewContractPDF(this.contractInfo.id, blob)
  }
}

