import firebase from 'firebase'
import firebaseJson from '../firebaseAccount.json'

const firebaseConfig = {
  apiKey: firebaseJson.apiKey,
  authDomain: firebaseJson.authDomain,
  projectId: firebaseJson.projectId,
  storageBucket: firebaseJson.storageBucket,
  messagingSenderId: firebaseJson.messagingSenderId,
  appId: firebaseJson.appId
};

firebase.initializeApp(firebaseConfig)

const firestore = firebase.firestore()
const storage = firebase.storage()

export { firestore, storage, firebase }